<template>
  <PdfPage :node="node">
    <HistoryTaxCondition mode="pdf" :node="node"></HistoryTaxCondition>
  </PdfPage>
</template>

<script>
import PdfPage from "@/pages/pdfFrontGenerator/components/PdfPage";
import {getCompanyInfo} from "@/api/threeYears";
import FinanceAndDuban
  from "@/pages/Result/ThreeYearResult/financialAnalysis/financeAndDuban/FinanceAndDuban";
import HistoryTaxCondition
  from "@/pages/Result/ThreeYearResult/financialAnalysis/historyTaxCondition/HistoryTaxCondition";

export default {
  name: "HistoryTaxConditionPdf",
  props: {
    pageStartFrom: Number,
    orderStartFrom: Number,
    offset: Number
  },
  components: {
    PdfPage,
    HistoryTaxCondition
  },
  data() {
    return {
      loaded: false,
      companyInfo: {}
    }
  },
  async mounted() {
    this.loaded = false
    this.loaded = true
    this.$eventBus.$emit('appendToc', this.node)
  },
  methods: {

  },
  computed: {

    node() {
      return {
        page: this.pageStartFrom + this.offset,
        orderNumber: this.orderStartFrom + this.offset,
        treeDataItem: {
          titleName: '历史纳税情况',
          children: [
            {
              titleName: '近三次纳税信用等级',
            },
            {
              titleName: '税收违法信息',
            }
          ]
        },
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"


.pdf-page-wrapper {
  padding 33px 0
}
</style>
