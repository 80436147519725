<template>
  <div class="pdf-page-wrapper pdf-page pdf-mode">
    <div class="title">目&nbsp;&nbsp;&nbsp;&nbsp;录</div>
    <div class="content-wrapper">
      <div v-for="(node,index) in data" class="level-wrapper-0" :key="index" v-if="node.isDisplay">
        <div class="title-level-0">
          <div class="name">{{ node.orderNumber|chineseNumber}}、{{ node.titleName }}</div>
        </div>
        <div v-for="(node2,index) in node.children" class="level-wrapper-1" :key="index"
             v-if="node.children&&node.children.length>0&&node2.isDisplay">
          <div class="title-level title-level-1">
            <div class="name">{{ node2.orderNumber }}、{{ node2.titleName }}</div>
            <div class="page">
              {{ node2.page|padNumber }}
            </div>
          </div>
          <div class="title-level title-level-2" v-for="(node3,index) in node2.children"
               :key="index" v-if="node2.children&&node2.children.length>0&&node3.isDisplay">
            <div class="name">
              {{ node3.orderNumber }}、{{ node3.titleName }}
            </div>
            <div class="page">
              {{ node3.page|padNumber }}
            </div>

          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: "Catlog",
  props: {
    data: [Array]
  },
  computed: {

  },
  methods:{
  }
}
</script>

<style lang="stylus" scoped>
.pdf-page-wrapper {
  padding-bottom 67px
}

.content-wrapper {
  margin-left 111px;
  margin-right 89px
  margin-top 89px

  .level-wrapper-0 {
    margin-bottom 44px
  }

  .level-wrapper-1 {
    margin-bottom 33px
  }

  .title-level {
    display flex;
    align-items center
    background-image url("~@/assets/result/pdf/dot.png")
    background-repeat repeat-x
    background-size auto 4px
    background-position 50% 50%

    .name {
      background #fff
      padding-right 33px
    }

    .page {
      background #fff
      padding-left 33px
    }
  }

  .title-level-0 {
    font-size: 33px;
    margin-bottom 47px
    font-weight: bold;
    color: #1D2129;
    line-height: 50px;
  }

  .title-level-1 {
    position relative;
    font-weight: 600;
    color: #1D2129;
    margin-left 19px
    line-height: 42px;
    display flex
    justify-content space-between

    .name {
      font-size: 28px;
    }

    line-height 25px

    .line {
      //background url("")
    }

    .page {
      font-size: 28px;
      font-weight: 400;
      color: #4E5969;
      line-height: 42px;
    }
  }

  .title-level-2 {
    display flex
    justify-content space-between
    font-size: 22px;
    font-weight: 500;
    color: #4E5969;
    line-height: 33px;
    margin 17px 0
    margin-left 64px
  }
}

.title {
  font-size: 100px;
  font-weight: 600;
  color: #1D2129;
  line-height: 150px;
  margin 0 auto
  margin-top 67px
}
</style>
