import { render, staticRenderFns } from "./wordResult.vue?vue&type=template&id=0b386faf&scoped=true"
import script from "./wordResult.vue?vue&type=script&lang=js"
export * from "./wordResult.vue?vue&type=script&lang=js"
import style0 from "./wordResult.vue?vue&type=style&index=0&id=0b386faf&prod&lang=stylus&scoped=true&media=print"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b386faf",
  null
  
)

export default component.exports