<template>
  <div :class="{ TaxIndexPdf: mode === 'pdf', TaxIndex: mode === 'default' }">
    <div class="body-wrapper">
      <div class="desc">
        <div>分析结果</div>
        <div>
          {{ dataText }}
        </div>
      </div>
      <div class="chart" v-if="wordData">
        <GenericTable :data="wordData"></GenericTable>
      </div>
      <div class="desc desc1">
        <div>核实思路：</div>
      </div>
      <!-- <div class="chart-wrapper" v-if="showChart">
        <div class="chart">
          <div class="chartColumn" id="chartColumn" ref="chart"></div>
        </div>
        
      </div> -->
    </div>
  </div>
</template>

<script>
import GenericTable from "../InvoiceIndex/components/GenericTable";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import mixin from "@/pages/ResultEditor/mixin.js";
import WarningBadge from "@/pages/ResultEditor/components/WarningBadge";
import echarts from "echarts";
export default {
  mixins: [mixin],
  components: {
    GenericTable,
  },
  props: {
    node: Object,
    mode: {
      default: "default",
      type: [String],
    },
  },
  data: function () {
    return {
      title: "",
      desc: [],
      x: [],
      rects: [],
      lines: new Array(10),
      showChart: true,
      warningId: null,
      yeb: null,
      wordData: "",
    };
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    //行业参考值的名字
    industryReferenceValueLabel() {
      return this.pdfData.header[2];
    },
    //主要的指标名字
    mainBarLabel() {
      return this.pdfData.header[1];
    },
    data() {
      return this.node;
    },
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = cloneDeep(this.data);
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });

        // this.findTheNodeByIndexes({
        //   dataText: value
        // });
        // this.$parent.setTreeNodeAttribute({
        //   dataText: value
        // });
      },
    },
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    pdfData: function () {
      return JSON.parse(this.data.pdfData);
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
  },
  mounted() {
    this.getData(this.data.titleName);
    //debugger

    // if (this.showChart) {
    //   this.showMap();
    // }
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    getData(name) {
      this.title = name;
      const _this = this;
      //     {
      //   MLL_AVG: this.pdfData.MLL_AVG,
      //   MLL: this.pdfData.MLL,
      //   MLL_SQJE: this.pdfData.MLL_SQJE,
      //   ZZSSFL_AVG: this.pdfData.ZZSSFL_AVG,
      //   ZZSSFL: this.pdfData.ZZSSFL,
      //   ZZSSFL_SQJE: this.pdfData.ZZSSFL_SQJE,
      //   SDSFL_AVG: this.pdfData.SDSFL_AVG,
      //   SDSFL: this.pdfData.SDSFL,
      //   SDSFL_SQJE: this.pdfData.SDSFL_SQJE,
      //   YYSJJFJL_AVG: this.pdfData.YYSJJFJL_AVG,
      //   YYSJJFJL: this.pdfData.YYSJJFJL,
      //   YYSJJFJL_SQJE: this.pdfData.YYSJJFJL_SQJE
      // }

      this.x = this.pdfData.body.map((v) => v[""]);

      this.rects = this.pdfData.body.map((v) => v[this.mainBarLabel]);
      this.lines[0] = this.pdfData.body[0][this.industryReferenceValueLabel];
      this.lines.fill(this.pdfData.body[1][this.industryReferenceValueLabel], 1);

      this.showChart = true;
      //
      // console.log(this.pdfData);
      let wordData = this.pdfData;
      let header = wordData.header;
      if (this.mode == "pdf" && wordData.pdfHeader) {
        header = wordData.pdfHeader;
      }
      if (!wordData) {
        return null;
      }

      if (!wordData.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < wordData.body.length; i++) {
        ret[i] = [];

        header.forEach((key) => {
          ret[i].push(wordData.body[i][key]);
        });
      }
      this.wordData = {
        data: ret,
        labels: header,
      };
      // console.log(this.wordData);
    },
    //echart
    // showMap() {
    //   this.chartColumn = echarts.init(this.$refs["chart"]);
    //   const option = {
    //     color: ["#3398DB"],
    //     tooltip: {
    //       formatter: (params) => {
    //         const text = params[0].seriesName.includes("行业")
    //           ? ""
    //           : `${params[0].seriesName}:${params[0].value}%`;

    //         return text;
    //       },
    //       trigger: "axis",
    //       axisPointer: {
    //         // 坐标轴指示器，坐标轴触发有效
    //         type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
    //       },
    //     },
    //     legend: {
    //       x: "center",
    //       bottom: "5%",
    //       data: [this.mainBarLabel, this.industryReferenceValueLabel],
    //     },
    //     grid: {
    //       left: "3%",
    //       right: "4%",
    //       bottom: "15%",
    //       top: "15%",
    //       containLabel: true,
    //     },
    //     xAxis: [
    //       {
    //         type: "category",
    //         data: [this.x[0], this.x[1], " ", " ", " ", " ", " ", " ", " ", " "],
    //         splitLine: {
    //           show: true,
    //           lineStyle: {
    //             color: "#EEEEEE",
    //             width: 1,
    //             type: "solid",
    //           },
    //         },
    //         axisLine: {
    //           show: true,
    //           lineStyle: {
    //             color: "#666",
    //           },
    //         },
    //         axisLabel: {
    //           // rotate: 35,
    //           interval: 0,
    //           textStyle: {
    //             fontSize: 11,
    //             fontFamily: "PingFangSC-Regular,PingFang SC",
    //             color: "rgba(0,0,0,0.6)",
    //           },
    //         },
    //       },
    //     ],
    //     yAxis: [
    //       {
    //         type: "value",
    //         max: () => {
    //           let max = Math.max(...this.lines, ...this.rects);
    //           if (max > 0) {
    //             max = Math.ceil((max * 100) / 100);
    //           } else {
    //             max = Math.floor((max * 100) / 100);
    //           }

    //           return max < 0 ? 0 : max;
    //         },
    //         min: () => {
    //           let min = Math.min(...this.lines, ...this.rects);

    //           if (min > 0) {
    //             min = Math.ceil((min * 100) / 100);
    //           } else {
    //             min = Math.floor((min * 100) / 100);
    //           }

    //           min = Math.ceil((min * 100) / 100);
    //           return min > 0 ? 0 : min;
    //         },
    //         axisLine: {
    //           show: true,
    //           lineStyle: {
    //             color: "#666",
    //           },
    //         },
    //         splitLine: {
    //           show: true,
    //           lineStyle: {
    //             color: "#EEEEEE",
    //             width: 1,
    //             type: "dashed",
    //           },
    //         },
    //         axisLabel: { show: true, formatter: "{value} %" },
    //       },
    //       {
    //         type: "value",
    //         name: "",
    //         max: () => {
    //           let max = Math.max(...this.lines, ...this.rects);
    //           if (max > 0) {
    //             max = Math.ceil((max * 100) / 100);
    //           } else {
    //             max = Math.floor((max * 100) / 100);
    //           }
    //           return max < 0 ? 0 : max;
    //         },
    //         min: () => {
    //           let min = Math.min(...this.lines, ...this.rects);
    //           if (min > 0) {
    //             min = Math.ceil((min * 100) / 100);
    //           } else {
    //             min = Math.floor((min * 100) / 100);
    //           }
    //           return min > 0 ? 0 : min;
    //         },
    //         axisLine: {
    //           show: false,
    //           lineStyle: {
    //             color: "#666",
    //           },
    //         },
    //         axisTick: {
    //           // 坐标轴 刻度
    //           show: false, // 是否显示
    //         },
    //         splitLine: {
    //           show: false,
    //           lineStyle: {
    //             color: "#EEEEEE",
    //             width: 1,
    //             type: "dashed",
    //           },
    //         },
    //         axisLabel: { show: false },
    //       },
    //     ],
    //     series: [
    //       {
    //         name: this.mainBarLabel,
    //         type: "bar",

    //         center: ["50%", "1%"],
    //         color: ["#4080FF"],
    //         barWidth: "30",
    //         data: this.rects,
    //       },
    //       {
    //         name: this.industryReferenceValueLabel,
    //         type: "line",
    //         yAxisIndex: 1,
    //         color: ["#FF9B2F"],
    //         data: this.lines,
    //       },
    //     ],
    //   };
    //   this.chartColumn.setOption(option);
    // },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  padding: 16px;
  background: RGBA(251, 251, 251, 1);

  .textarea-wrapper {
    margin: 16px;

    .isModified.textarea {
      >>> .el-textarea__inner {
        border: 1px solid #faad14 !important;
        border-color: #faad14 !important;
      }

      border-radius: 4px;
    }

    .isModifiedByMyself.textarea {
      >>> .el-textarea__inner {
        border: 1px solid rgba(245, 34, 45, 1) !important;
        border-color: rgba(245, 34, 45, 1) !important;
      }

      border-radius: 4px;
    }
  }
}

// .TaxIndex {
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
//   border-radius: 8px 8px 0px 0px;
//   margin-bottom: 16px;

//   .body-wrapper {
//     display: flex;
//     background: rgba(47, 84, 235, 0.04);

//     .desc {
//       padding: 16px 24px;
//       width: 380px;
//       box-sizing: border-box;
//       flex-grow: 0;
//       font-size: 14px;
//       color: #666;

//       p {
//         font-size: 14px;
//         color: #666;
//         line-height: 20px;
//       }
//     }

//     .chart-wrapper {
//       background: #fff;
//       flex-grow: 1;

//       .chart {
//         width: 764px;
//         height: 261px;
//         background: #fff;

//         .chartColumn {
//           height: 261px;
//           width: 764px;

//           >>>div:first-child {
//             width: 100% !important;
//           }
//         }
//       }
//     }
//   }
// }
.TaxIndexPdf{

  .body-wrapper {
    // display flex
    // flex-direction column
    .desc {
      font-size: 12px;
      color: #666;
      width: 100%;
      background: #DAE3F3;
      font-size: 12px;
      font-family: "微软雅黑";
      color: #1D2129;
      // border: 1px solid #1D2129;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      line-height: 1;

      p {
        padding 0
        margin 0
      }

      .title {
        font-size: 22px;
        color: #1D2129;
        line-height: 1.5;
        font-weight: 600;
      }
    }

    .desc1 {
      background: none;
    }


    .chart-wrapper {
      background: #fff;
      flex-grow: 1;

      .chart {
        width: 1072px;
        height: 336px;
        background: #fff;

        .chartColumn {
          height: 336px;
          width: 764px;

          >>>div:first-child {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  display: flex;
}



.TaxIndex {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 16px;
}

.TaxIndex:last-child {
  margin-bottom: 0;
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  display: flex;
}
</style>
