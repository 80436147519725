<template>
  <div class="pdf-page-wrapper">
    <div class="pdf-page">
      <img :src="data.backCoverImage" class="coverImage" crossorigin="anonymous"/>
    </div>
  </div>

</template>

<script>

export default {
  name: "BackCover",
  props: {
    data: Object
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"
.coverImage {
  width 100%
}

.cover {
  width 100%
  position absolute;
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;

  .logo {
    width 329px
    height 100px;
    top 67px;
    left 67px
    position absolute
  }
  .companyName{
    font-size 36px
    color #060b55;
    font-weight bolder
    top 187px;
    left 67px
    position absolute
  }
  .title {
    font-size 64px
    color #060b55;
    font-weight bolder
    top 187px;
    left 67px
    position absolute
  }
  .bottom {
    font-size: 33px;
    font-weight: bold
    color: #030852;
    line-height: 50px;
    position absolute
    bottom 67px;
    left 67px
    z-index 1
  }
}
</style>
