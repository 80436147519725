<template>
  <PdfPage :node="node">
    <FinanceAndDuban mode="pdf" :node="node"></FinanceAndDuban>
  </PdfPage>
</template>

<script>
import PdfPage from "@/pages/pdfFrontGenerator/components/PdfPage";
import {getCompanyInfo} from "@/api/threeYears";
import FinanceAndDuban
  from "@/pages/Result/ThreeYearResult/financialAnalysis/financeAndDuban/FinanceAndDuban";

export default {
  name: "FinanceAndDubanPdf",
  props: {
    pageStartFrom: Number,
    orderStartFrom: Number,
    offset: Number
  },
  components: {
    PdfPage,
    FinanceAndDuban
  },
  data() {
    return {
      loaded: false,
      companyInfo: {}
    }
  },
  async mounted() {
    this.loaded = false
    await this.loadCompanyInfo();
    this.loaded = true
    this.$eventBus.$emit('appendToc', this.node)
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({programId: this.$route.params.uuid})
      this.companyInfo = res.data
    },
  },
  computed: {
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      if (!this.companyInfo.zq) {
        return []
      }
      const zq = parseInt(this.companyInfo.zq.replace("004"))
      const ret = [zq, zq - 1, zq - 2]
      return ret
    },
    node() {
      return {
        page: this.pageStartFrom + this.offset,
        orderNumber: this.orderStartFrom + this.offset,
        treeDataItem: {
          titleName: '财务分析',
          children: [
            {
              titleName: '关键财务指标',
            },
            {
              titleName: '杜邦分析',
              children: this.yearsOption.map(year => {
                return {titleName: `${year}年杜邦分析`}
              })
            }
          ]
        },
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"


.pdf-page-wrapper {
  padding 33px 0
}
</style>
