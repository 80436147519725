<template>
  <div class="pdf-page-wrapper pdf-page" >
    <div class="header">
      <div class="title">{{ node.orderNumber|chineseNumber }}、{{ node.treeDataItem.titleName }}</div>
      <div class="border"></div>
    </div>
    <div >
      <slot></slot>
    </div>


    <!-- <div class="footer"> <div class="page">{{node.page|padNumber}}</div></div> -->
  </div>
</template>

<script>
import {getCompanyInfo, getFinancialRisk} from "@/api/threeYears";

export default {
  name: "PdfPage",
  props:{
    node: Object,
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"

.pdf-page-wrapper{
  padding 33px 0
}
</style>
