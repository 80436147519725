<template>
  <div :class="{TaxIndexPdf:mode==='pdf',TaxIndex:mode==='default'}">
    <div class="body-wrapper">
      <div class="desc">
        <p>{{ dataText }}</p>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {cloneDeep} from "lodash";
import mixin from "@/pages/ResultEditor/mixin.js";
import echarts from "echarts";

export default {
  name: 'SimpleTextContent',
  mixins: [mixin],
  components: {
  },
  props: {
    node: Object,
    mode: {
      default: 'default',
      type: [String]
    }
  },
  data: function () {
    return {
      title: "",
      desc: [],
      x: [],
      rects: [],
      lines: new Array(10),
      showChart: true,
      warningId: null,
      yeb: null,
    };
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    //行业参考值的名字
    industryReferenceValueLabel() {
      return this.pdfData.header[2];
    },
    //主要的指标名字
    mainBarLabel() {
      return this.pdfData.header[1];
    },
    data() {
      return this.node;
    },
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = cloneDeep(this.data);
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });

        // this.findTheNodeByIndexes({
        //   dataText: value
        // });
        // this.$parent.setTreeNodeAttribute({
        //   dataText: value
        // });
      },
    },
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    pdfData: function () {
      return JSON.parse(this.data.pdfData);
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
  },
  mounted() {

  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),

  },
};
</script>


<style lang="stylus" scoped>
.wrapper {
  padding: 16px;
  background: RGBA(251, 251, 251, 1);

  .textarea-wrapper {
    margin: 16px;

    .isModified.textarea {
      >>> .el-textarea__inner {
        border: 1px solid #faad14 !important;
        border-color: #faad14 !important;
      }

      border-radius: 4px;
    }

    .isModifiedByMyself.textarea {
      >>> .el-textarea__inner {
        border: 1px solid rgba(245, 34, 45, 1) !important;
        border-color: rgba(245, 34, 45, 1) !important;
      }

      border-radius: 4px;
    }
  }
}

.TaxIndex {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 16px;

  .body-wrapper {
    display: flex;
    background: rgba(47, 84, 235, 0.04);

    .desc {
      padding: 16px 24px;
      width: 380px;
      box-sizing: border-box;
      flex-grow: 0;
      font-size: 14px;
      color: #666;

      p {
        font-size: 14px;
        color: #666;
        line-height: 20px;
      }
    }

    .chart-wrapper {
      background: #fff;
      flex-grow: 1;

      .chart {
        width: 764px;
        height: 261px;
        background: #fff;

        .chartColumn {
          height: 261px;
          width: 764px;

          >>> div:first-child {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.TaxIndexPdf {

  .body-wrapper {
    display flex
    flex-direction column

    .desc {
      padding: 24px;
      line-height: 1.5;
      font-size: 14px;
      flex-shrink 0
      color: #666;
      width: 100%;
      box-sizing: border-box;
      background: rgba(47, 84, 235, 0.04);
      padding 22px
      font-size: 22px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #1D2129;
      line-height: 1.6;
      box-sizing: border-box;
      background: #F2F3F5;

      p {
        padding 0
        margin 0
      }

      .title {
        font-size: 22px;
        color: #1D2129;
        line-height: 1.5;
        font-weight: 600;
      }
    }


    .chart-wrapper {
      background: #fff;
      flex-grow: 1;

      .chart {
        width: 1072px;
        height: 336px;
        background: #fff;

        .chartColumn {
          height: 336px;
          width: 764px;

          >>> div:first-child {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  display: flex;
}


.TaxIndex {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 16px;
}

.TaxIndex:last-child {
  margin-bottom: 0;
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  display: flex;
}


</style>
