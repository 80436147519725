<template>
  <!-- 高风险概述 -->
  <div :class="{ wrapperPdf: mode === 'pdf', wrapper: mode === 'default' }">
    <div class="zd1">
      <div class="box1">
        <div class="desc">
          <div class="always-show-wrapper">
            <div
              class="item pdf-item pdf-description-wrapper"
              v-if="pdfData.ruleRiskStatement"
            >
              <div class="title red">分析结果</div>
              {{ pdfData.ruleRiskStatement }}
            </div>
            <!-- <div class="item pdf-item pdf-resolve-wrapper" v-if="pdfData.ruleAdvise">
              <div class="title blue">解决方案</div>
              {{ pdfData.ruleAdvise }}
            </div> -->
            <!-- <template v-if="mode == 'pdf'">
              <div class="item pdf-item pdf-other-wrapper" v-if="pdfData.policy">
                <div class="title blue">法规</div>
                {{ pdfData.policy }}
              </div>
              <div
                class="item pdf-item pdf-other-wrapper"
                v-if="pdfData.caseText"
                style="margin-top: 24px"
              >
                <div class="title blue">案例</div>
                {{ pdfData.caseText }}
              </div>
            </template> -->
          </div>
          <template v-if="mode == 'default'">
            <!-- <div class="more" v-if="showMore">
              <div class="item" v-if="pdfData.policy">
                <div class="title blue">法规</div>
                {{ pdfData.policy }}
              </div>
              <div class="item" v-if="pdfData.caseText" style="margin-top: 24px">
                <div class="title blue">案例</div>
                {{ pdfData.caseText }}
              </div>
            </div> -->
            <div class="btn-more-wrapper" v-if="pdfData.caseText || pdfData.policy">
              <button
                class="btn btn-default btn-show-more"
                style="margin-right: 0"
                @click="toggleShowMore"
              >
                {{ btnShowMoreLabel }}
              </button>
            </div>
          </template>
          <div v-if="wordData">
            <GenericTable :data="wordData"></GenericTable>
          </div>
          <div class="desc1">核实思路：</div>
        </div>
        <!-- <div
          class="qiangzhi"
          :class="node.showContent ? 'leftnew' : 'left'"
          v-if="
            pdfData.ruleParamsTemplete && !isAllContentEmpty(pdfData.ruleParamsTemplete)
          "
        >
          <RiskProblemEchart
            :list="pdfData.ruleParamsTemplete"
            v-if="pdfData.ruleParamsTemplete"
            class="chart"
          ></RiskProblemEchart>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { merge, cloneDeep } from "lodash";

import RiskProblemEchart from "./RiskProblemEchart";
import ColumnChart from "@/pages/Result/components/chats/ColumnChart";
import LineChart from "@/pages/Result/components/chats/LineChart";
import GenericTable from "@/pages/ResultEditor/componentsWord/InvoiceIndex/components/GenericTable";

export default {
  components: {
    RiskProblemEchart,
    ColumnChart,
    LineChart,
    GenericTable,
  },
  computed: {
    ...mapState("Result", ["resultData"]),
    btnShowMoreLabel() {
      return this.showMore ? "收起" : "展开";
    },
    typeClass() {
      return this.node.contrastKey == "SWHighRisk" ? "red" : "yellow";
    },
    pdfData() {
      return JSON.parse(this.node.pdfData);
    },
    charts() {
      const data = [
        {
          imageType: "brokenLine",
          series: [{ data: ["85.49", "33.23", "70.42"], name: "产成品" }],
          xLabels: ["4月", "5月", "6月"],
        },
        {
          imageType: "histogram",
          series: [
            { data: ["85.49", "33.23", "70.42"], name: "产成品" },
            { data: ["33.23", "82.00", "22.42"], name: "原材料投入" },
          ],
          xLabels: ["4月", "5月", "6月"],
        },
        {
          imageType: "brokenLine",
          series: [
            { data: ["85.49", "33.23", "70.42"], name: "产成品" },
            { data: ["33.23", "82.00", "22.42"], name: "原材料投入" },
          ],
          xLabels: ["4月", "5月", "6月"],
        },
        {
          imageType: "histogram",
          series: [{ data: ["82.36", "67.46", "60.06"], name: "原材料占比" }],
          xLabels: ["4月", "5月", "6月"],
          unit: "%",
        },
        {
          imageType: "twoHistogram",
          series: [
            { data: ["85.49", "70.42"], name: "4月" },
            { data: ["33.23", "22.42"], name: "5月" },
          ],
          xLabels: ["产成品", "原材料投入"],
        },
        {
          imageType: "histogram",
          series: [
            {
              data: ["85.49", "33.23", "82.00"],
              name: "产成品",
            },
            {
              data: ["70.42", "22.42", "49.25"],
              name: "原材料投入",
            },
          ],
          xLabels: ["2021年04月", "2021年05月", "2021年06月"],
        },
      ];

      return data;
    },
  },
  props: {
    node: Object,
    mode: {
      default: "default",
      type: [String],
    },
  },
  data() {
    return {
      showMore: false,
      activeNames: "",
      num: 0,
      list: [],
      istrue: false,
      topshow: false,
      wordData: "",
    };
  },
  mounted() {
    let data = JSON.parse(this.node.pdfData);
    let body = JSON.parse(data.ruleParamsTemplete);
    let tableBody = body.diagramResultDtoList;
    let tableHeader = ["-"].concat(body.timeSlot);
    let tableBodyData = [[], [], [], [], []];
    // console.log(tableBody)
    // let
    let ret = [];
    tableBody.forEach((grp) => {
      const item = {};
      grp.data.forEach((v) => {
        if (v.value) {
          item[v.label] = parseFloat(v.value);
        }
      });
      item["-"] = grp.product;
      ret.push(item);
    });
    // console.log(ret);
    const ret2 = [];

    for (let i = 0; i < ret.length; i++) {
      ret2[i] = [];

      tableHeader.forEach((key) => {
        ret2[i].push(ret[i][key]);
      });
    }
    this.wordData = {
      data: ret2,
      labels: tableHeader,
    };
    // console.log(this.wordData);
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    isAllContentEmpty(ruleParamsTemplete) {
      // debugger
      if (!ruleParamsTemplete) {
        return true;
      } else {
        const arr = JSON.parse(ruleParamsTemplete);
        // debugger
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.wrapper {
  .chart{
    width: 533px;
    height: 300px;

  }
  .grp-title {
    font-size: 18px;
    padding: 16px 24px;
    font-weight: bold;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgb(217, 217, 217);
    border-radius: 8px 8px 0px 0px;

    .red {
      color: #F5222D;
    }

    .yellow {
      color: #FAAD14;
    }
  }

  .risk-problem-wrapper {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px 8px 0px 0px;
    background: #fff;

    .bttitle {
      background: rgba(255, 255, 255, 1);
      border-radius: 4px 4px 0px 0px;
      border-bottom: 1px solid #D9D9D9;
      font-size: 16px;
      font-weight: bold;
      color: #333;
      padding: 16px 24px;
    }
  }

  .zd1 {
    width: 100%;

    .box1 {
      will-change: height;
      box-sizing: border-box;
      display: flex;

      .leftnew {
        padding: 24px 24px 0;
        flex-grow: 1;
      }

      .desc {
        padding: 24px;
        width: 538px;
        box-sizing: border-box;
        background: rgba(47, 84, 235, 0.04);
        font-size: 14px;
        line-height: 1.5;
        flex-grow: 0;
        color: #666;

        .always-show-wrapper {

        }

        .item {
          margin-bottom: 24px;

          .title {

          }

          .title.red {
            color: #F5222D;
          }

          .title.blue {
            color: #2F54EB;
          }
        }

        .btn-more-wrapper {
          display flex
          justify-content flex-end

          .btn-show-more {
            width 100%
          }
        }

      }
    }


  }


  .tab-content {
    padding: 20px 10px;
  }

  .riskgay {
    margin: 0 auto;

    .right_c {
      margin: 0 auto;
      background: #f5f7fc;
      overflow: hidden;
      width: 96%;

      .bgimg {
        margin-top: 30px;
        z-index: 1;
        margin-left: 15px;
        width: 96%;
      }

      .section {
        background: #fff;
        position: relative;
        top: -60px;
        margin-left: 30px;
        z-index: 2;
        // margin: 15px;
        border-radius: 8px;
        padding: 20px;
        width: 91.5%;

        .header {
          font-size: 22px;
          color: #062340;
          border-bottom: 1px solid #D4E7FF;
          padding-bottom: 15px;
        }

        .tab-wrapper {
          position: relative;

          >>> .el-tabs {
            .el-tabs__header {
              min-height: 250px;
              max-height: 500px;
              overflow-y: scroll;
              scrollbar-width: none; /* firefox */
              -ms-overflow-style: none; /* IE 10+ */
            }

            .el-tabs__header::-webkit-scrollbar {
              display: none; /* Chrome Safari */
            }

            .el-tabs__item {
              text-align: left;
            }
          }
        }
      }
    }
  }

  >>> .el-tabs__header {
    background: #F5F9FD;
    min-height: 400px;

    .el-tabs__item {
      line-height: 1.5;
      width: 320px;
      white-space: normal;
      padding: 15px 20px 15px 10px;
      font-size: 16px;
      box-sizing: border-box;
      height: auto;
    }
  }

  .problem-list {
    color: #6B7897;

    .title {
      font-weight: bold;
      margin: 20px 0;
    }
  }

  .more {
  }

  .hide-more {
    display none;
  }
}


.wrapperPdf {
  .chart{
    width: 1066px;
    height: 336px;

  }
  .pdf-item {
    line-height: 1;
    flex-shrink 0
    color: #666;
    width: 100%;
    box-sizing: border-box;

    // padding 22px
    font-size: 12px;
    font-family: "微软雅黑";
    font-weight: 500;
    color: #1D2129;
    line-height: 1;
    box-sizing: border-box;
    background: #DAE3F3;
    margin 0 auto;

    p {
      padding 0
      margin 0
    }

    .title {
      font-size: 12px;
      // line-height: 1.5;
      // font-weight: 600;
    }
  }
  .pdf-description-wrapper{
    // padding 22px 22px
    .title {
      font-size: 12px;
      // line-height: 1.5;
      // font-weight: 600;
      color: #1D2129;
    }

  }
  .pdf-resolve-wrapper{
    background none;
    padding 11px 22px
  }

  .pdf-other-wrapper{
    padding 11px 22px
    background none;
    color: #86909C;
  }
}

.box1 .desc .desc1 {
  background: none !important;
  font-size: 12px !important;
}
</style>
