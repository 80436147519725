<template>
  <div class="pdf-page-wrapper pdf-page">


      <div class="title">声&nbsp;&nbsp;&nbsp;&nbsp;明</div>
      <div class="text" v-html="$options.filters.formatText(data.statement)">
      </div>
      <div class="bottom">
        <div class="item">

          <div class="value"  style="text-align: right;padding-right: 4em">
            {{ data.assertor }}
          </div>

        </div>
        <div class="item">

          <div class="value" style="text-align: right;padding-right: 4em">
            {{ $store.state.ResultEditor.basicInfo.exeTime|timeFormat }}
          </div>
        </div>
        <div class="item seal"  v-if="data.officialSealImage">
          <div class="label">盖章：</div>
          <div class="seal-pic-wrapper">
            <img :src="data.officialSealImage" class="seal-pic" crossorigin="anonymous"></div>
        </div>

      </div>
    </div>

</template>

<script>

export default {
  name: "Declaration",
  props: {
    data: Object
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"
.pdf-page-wrapper {
  padding 0 89px
  display flex;
  flex-direction column
  min-height 1754px
  background #fff;

  .title {
    margin 100px auto 56px
    font-size: 44px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #1D2129;
    line-height: 67px;

  }

  .text {
    font-size: 25px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #1D2129;
    line-height: 44px;
    text-indent 2em;
  }

  .bottom {
    margin-top 111px

    .item {
      .label {
        width 120px
        text-align right
        align-items center
        display flex
        justify-content flex-end
      }
      display flex
      margin-bottom 33px
      font-size: 28px;
      font-weight: 400;
      color: #1D2129;
      line-height: 42px;
      justify-content flex-end

      .value {
        width 100%
        text-align right
      }
    }

    .item.seal {
      display flex;
      justify-content flex-end
      .seal-pic-wrapper {
        align-items center

        .seal-pic {
          width 240px
        }
      }

    }
  }

}
</style>
