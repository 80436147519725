<template>
  <div>
    <div v-if="mode=='pdf'">
      <div class="chart-wrapper">
        <!-- <template
          v-for="(chart, index) in data.data.ruleOfInvoiceImageList"
        >
          <ThreeEchart
            :key="index"
            :data="chart"
            v-if="chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'"
            class="chart"
            height="300px"
            :width="1120/data.data.ruleOfInvoiceImageList.length+'px'"
          ></ThreeEchart>
        </template> -->
      </div>
      <div class="table-wrapper">
        <BigPowerTable :data="data.data" class="table"></BigPowerTable>
      </div>
    </div>
    <div v-else>
      <div class="chart-wrapper">
        <!-- <template
          v-for="(chart, index) in data.data.ruleOfInvoiceImageList"
        >
          <ThreeEchart
            :key="index"
            :data="chart"
            v-if="chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'"
            class="chart"
            height="300px"
            :width="1200/data.data.ruleOfInvoiceImageList.length+'px'"
          ></ThreeEchart>
        </template> -->
      </div>
      <div class="table-wrapper">
        <BigPowerTable :data="data.data" class="table"></BigPowerTable>
      </div>
    </div>
  </div>


</template>

<script>
import ThreeEchart from "@/pages/Result/ThreeYearResult/financialAnalysis/ThreeEchart";
import LineChart from "@/pages/Result/components/chats/LineChart";
import ColumnChart from "@/pages/Result/components/chats/ColumnChart";
import BigPowerTable from "big-power-table"

export default {
  name: "KeyTaxRate",
  props: {
    data: Object,
    mode: String
  },
  components: {
    ThreeEchart,
    BigPowerTable
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="stylus" scoped>
.chart-wrapper
  display flex
</style>
