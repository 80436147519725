<template>
  <PdfPage :node="node">
    <UpDownCustomer mode="pdf" :node="node"></UpDownCustomer>
  </PdfPage>
</template>

<script>
import PdfPage from "@/pages/pdfFrontGenerator/components/PdfPage";
import UpDownCustomer from "@/pages/Result/ThreeYearResult/financialAnalysis/UpDownCustomer";

export default {
  name: "UpDownCustomerPdf",
  props: {
    pageStartFrom: Number,
    orderStartFrom: Number,
    offset: Number
  },
  components: {
    PdfPage,
    UpDownCustomer
  },
  data() {
    return {
      loaded: false
    }
  },
  async mounted() {
    this.loaded = false
    this.loaded = true
    this.$eventBus.$emit('appendToc', this.node)
  },
  methods: {},
  computed: {
    node() {
      return {
        page: this.pageStartFrom + this.offset,
        orderNumber: this.orderStartFrom + this.offset,
        treeDataItem: {
          titleName: '上下游客户分析',
          children: [
            {
              titleName: '上下游客户网络',
            },
            {
              titleName: '上下游风险企业',
              children: [
                {
                  titleName: '上游风险企业',

                },
                {
                  titleName: '下游风险企业',
                },
                {
                  titleName: '风险类型说明',
                }
              ]
            },
            {
              titleName: '上下游客户分布',
              children: [
                {
                  titleName: '上游客户地区分布图',

                },
                {
                  titleName: '下游客户地区分布图',
                }
              ]
            }
          ]
        },
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"


.pdf-page-wrapper {
  padding 33px 0
}
</style>
