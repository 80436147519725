<template>
    <div ref="theChart" class="echarts1">{{ list }}</div>
</template>
<script>
import echarts from "echarts";
import smoothNumber from "@/utils/smooth";
import {axisLabelFormatter} from "@/utils/helper";

const s = smoothNumber();

export default {
  data() {
    return {
      chartColumnM: null,
      chartData: [],
    };
  },
  props: ["list"],
  computed: {
    listObj() {
      return JSON.parse(this.list);
    },
    headers() {
      return this.listObj.timeSlot;
      //return this.listObj.diagramResultDtoList.map((v) => v.product);
    },
    subjects() {
      return this.listObj.timeSlot;
    },
    series() {
      let ret = [];

      const colors = [
        { type: "bar", color: "#597EF7", barWidth: "30" },
        { type: "bar", color: "#4E5969", barWidth: "30" },
        { type: "bar", color: "#4080FF", barWidth: "30" },
      ];

      for (let i = 0 ; i< this.listObj.timeSlot.length;i++) {
          const element = this.listObj.timeSlot.length[i];
          const color = colors[colors.length-i-1]
          ret.push(color)
      }


      return ret;
    },
    source() {
      const ret = [];

      this.listObj.diagramResultDtoList.forEach((grp) => {
        const item = {};
        grp.data.forEach((v) => {
          item[v.label] = parseFloat(v.value);
        });
        item.product = grp.product
        ret.push(item);
      });

      return ret;
    },
  },
  mounted() {

    // const source = [];

    // for (const subject of this.subjects) {
    //   const item = {};
    //   item.product = subject;
    //   for (const header of this.headers) {
    //     const theItem = this.listObj.find((v) => {
    //       return v.subject == subject && v.header == header;
    //     });

    //     //item[header] = s.smooth(theItem.content);
    //     item[header] = theItem.content;
    //   }
    //   source.push(item);
    // }

    const myChart = echarts.init(this.$refs.theChart);
    // const ruleParamsTemplete = [
    //   {
    //     data: [
    //       { label: 2017, value: 436492786.45 },
    //       { label: 2018, value: 436492786.45 },
    //       { label: 2019, value: 373180647.04 },
    //     ],
    //     product: "营业收入",
    //   },
    //   {
    //     data: [
    //       { label: 2017, value: 436492786.45 },
    //       { label: 2018, value: 431885971.13 },
    //       { label: 2019, value: 364886370.36 },
    //     ],
    //     product: "营业成本",
    //   },
    // ];
    const options = {
      legend: {
        x: "center",
        bottom: "5%",
      },
      tooltip: {
        formatter: (params) => {
          // const text = s.unSmooth(params.value[params.seriesName]);
          const text = params.value[params.seriesName];
          return text+'万元';
        },
      },
      dataset: {
        dimensions: ["product", ...this.headers],
        source: this.source,
      },

      xAxis: {
        type: "category",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "solid",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        axisLabel: {
          // rotate: 35,
          interval: 0,
          textStyle: {
            fontSize: 11,
            fontFamily: "PingFangSC-Regular,PingFang SC",
            color: "rgba(0,0,0,0.6)",
          },
          formatter:(value)=>{
            return axisLabelFormatter(value)
          }
        },
      },
      grid: {
        left: "3%",
        right: "5%",
        top: "10%",
        bottom: "20%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel:{formatter:'{value} 万元'},
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "dashed",
          },
        },
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: this.series,
    };
    myChart.setOption(options);
  },
};
</script>
<style lang="stylus" scoped>
.echarts1 {

  >>>div:first-child {
    width: 100% !important;
  }
}
</style>
