<template>


  <div>
    <div v-if="dataLoaded">
      <!--为园区时隐藏-->
      <template v-for="(aTreeData, index) in this.treeData">
        <div :key="index" class="box-wrapper" :class="'pages'+(index+5)">

          <TabContent
            :key="`a_${index}_${aTreeData.titleName}`"
            :node="aTreeData"
          ></TabContent>

          <!-- <Invoice

            v-if="resultTabName == '增值税专用发票风险评估'"
          ></Invoice>
          <risk-overall
            key="b"
            type="tax"
            @maskerBtnClick="handleMaskBtnClick"
            :node="aTreeData"
            v-if="resultTabName == '税务风险评估' && dataLoaded"
          ></risk-overall>
          <risk-overall
            key="c"
            type="finance"
            @maskerBtnClick="handleMaskBtnClick"
            :node="aTreeData"
            v-else-if="resultTabName == '财务风险评估' && dataLoaded"
          ></risk-overall> -->
        </div>
      </template>
    </div>
  </div>


</template>
<script>
import appendQuery from "append-query";
import {getPurchasedVersions, unlockHistoryTest} from "@/api/api";
import {mapState} from "vuex";
import BreadCrumb from "@/components/BreadCrumb";
import TabContent from "./wordComponents/TabContent";
import TaxSummary from "@/pages/Result//components/TaxSummary";
import OpsSection from "@/pages/ResultEditor/components/OpsSection/OpsSection.vue"
import specialResultHeadMixin from "@/pages/Result/specialResultHeadMixin";
import {getCompanyInfo} from "@/api/threeYears";

export default {
  name: "PdfDetailResult",
  components: {
    BreadCrumb,
    TabContent,
    TaxSummary,
    OpsSection,
  },
  data() {
    return {
      showFloatPdf: false,
      avaliableVersions: [],
      currentSlideIndex: 0,
      showdelete: true,
      pdfurl: "",
      showclick: true,
      resultTabName: "综合风险评估",
    };
  },
  props: {
    uuid: String,
    anchor: [String, Number],
    hideShare: {
      type: Boolean,
      default: false
    }
  },
  beforeCreate() {

  },
  async mounted() {
    window.addEventListener("scroll", this.scrollEventHandler)

  },
  activated() {
    const anchor = this.$route.query.anchor || this.anchor
    this.$scrollTo(document.querySelector("#node" + anchor), 1000);
  },
  mixins: [
    specialResultHeadMixin
  ],
  destroyed() {
    window.removeEventListener("scroll", this.scrollEventHandler)
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("ResultEditor", [
      "tag",
      "overall",
      "maskStatus",
      "treeData",
      "discountPolicyTree",
      "downloadUrl"
    ]),
    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0 || this.overall;
    },
  },
  methods: {
    scrollEventHandler() {
      if (window.scrollY < 100) {
        this.showFloatPdf = false;

      } else {
        this.showFloatPdf = true;
      }
    },
    handleSloganClick() {
      this.$refs["versionDescriptionDialog"].dialogVisible = true;
    },
    handleDialogApply() {
      this.$refs["contactDialog"].dialogVisible = true;
    },
    switchTab(tabName, index) {
      this.resultTabName = tabName;
    },
    isDisplayTab(contrastKey) {
      if (contrastKey == "CW" || contrastKey == "SW") {
        return this.tag != "5";
      } else if (contrastKey == "FP") {
        return this.tag == "4" || this.tag == "5";
      }
    },


    handleNavClick(e) {
      if (e.target.classList.contains("item")) {
        const element = e.target;
        const parent = element.parentNode.children;
        this.currentSlideIndex = [...parent].indexOf(element);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.result-wrapper-color {
  background-image: url('~@/assets/result/bg.png');
  background-color: #2F54EB;
}


.floatSlogan {
  position: fixed;
  bottom: 70px;
  width: 308px;
  right: 28px;
  cursor: pointer;
}

>>> .el-tabs__header {
  border-bottom: none;
  margin: 24px 0 16px;

  .el-tabs__nav-scroll {
  }

  .el-tabs__item {
    border: 0 none;
    line-height: 36px;
    height: 36px;
    margin-right: 16px;
  }

  .el-tabs__item.is-active {
    background: rgba(47, 84, 235, 1);
    border-radius: 18px;
    color: #fff;
  }

  .el-tabs__nav {
    border: 0 none;
  }
}

.title-content {
  // width: 1260px;
  padding: 10px 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.qymc-title {
  display: flex;
}

.qyImg {
  width: 40px;
  height: 40px;
  margin-left: 24px;
}

.btn-download {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #2F54EB;
  cursor: pointer;
}

.floatDownloadPdf {
  width 84px;
  position fixed;
  right 57px;
  top 100px
  cursor pointer
}
</style>
