<template>
  <span class="badge-wrapper" :class="{'pdf-badge-wrapper':mode==='pdf'}">
    <span class v-if="id==0"></span>
    <span class="badge red" v-else-if="id==1">高风险</span>
    <span class="badge yellow" v-else-if="id==2">中风险</span>
    <span class="badge green" v-else-if="id==3">低风险</span>
    <!--    <div class="badge grey" v-else-if="id==4">无风险</div>-->
  </span>
</template>

<script>
export default {
  props: {
    id: [Number, String],
    mode: {
      default: 'default',
      type: [String]
    }
  }
};
</script>

<style lang="stylus" scoped>
.badge {
  font-weight normal;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.2);
  // border: 1px solid rgba(0, 0, 0, 0.2);
  // background: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  // display: inline-block;
  // font-size: 28px;
  margin-left: 16px;
}

.pdf-badge-wrapper {
  .badge {
    padding: 3px 22px;
    // font-size: 28px;
  }
}

.badge.red {
  color: #F53F3F;
  // background: #FFECE8;
  // border: 1px solid #F53F3F;
}

.badge.yellow {
  color: #FF7D00;
  // background: #FFF7E8;
  // border-radius: 3px;
  // border: 1px solid #FF7D00;
}

.badge.green {
  color: #00B42A;
  // background: #E8FFEA;
  // border: 1px solid #00B42A;
}

.badge.grey {
  color: rgba(0, 0, 0, 0.2);
  // border: 1px solid rgba(0, 0, 0, 0.2);
  // background: rgba(0, 0, 0, 0.04);
}

.badge-wrapper {
  display flex
  align-items center
}
</style>
